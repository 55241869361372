#text {
	margin-top: 12px;
}

.changePass {
	margin-top: 22px;
}

#submit {
	margin: 25px 0;
}

.accountInp{
	width: 300px;
}